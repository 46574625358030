<template>
     <!-- ======= Header ======= -->
     <header id="header" class="fixed-top d-flex align-items-center header-transparent">
    <div class="container d-flex align-items-center justify-content-between">
      <div class="logo">
        <a href="/" style="display: inline-block;"> 
        <object style="pointer-events: none; height: 42px" type="image/svg+xml" data="assets/img/plcjs-logo.svg"></object>
        </a> 
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Главная</a></li>
          <li><a class="nav-link scrollto" href="#about" >О проекте</a></li>
          <li><a class="nav-link scrollto" href="#features">Возможности</a></li>
          <li><a class="nav-link scrollto" href="#gallery">Галерея</a></li>
          <!-- <li><a class="nav-link scrollto" href="#team">Команда</a></li> -->
          <!-- <li><a class="nav-link scrollto" href="/store">Купить</a></li> -->
   
          <li><a class="nav-link scrollto" href="#faq">FAQ</a></li>
  
          <li class="dropdown"><a href="#"><span>Поддержка</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              
              <li><a href="https://gitflic.ru/project/glazko/plcjs.wiki" target="_blank">Документация</a></li>
              <li><a href="https://gitflic.ru/project/glazko/plcjs.wiki" target="_blank">Программное обеспечение</a></li>
              <li><a href="/sert">Сертификаты</a></li>
              <li><a href="http://t.me/plcjs" target="_blank">t.me/plcjs</a></li>
            </ul>
          </li>
          <li><a class="nav-link scrollto" href="#contact">Контакты</a></li>
          <!-- <li><a class="nav-link scrollto" href="/account" v-if="!(useAuthStore1.isLoggedIn)">Войти</a></li>
          <li class="dropdown" v-if="useAuthStore1.isLoggedIn"><a href="#"><span>{{ useAuthStore1.payload.email }}</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="/account">Профиль</a></li>
              <li><a href="">Выйти</a></li>
            </ul>
          </li> -->
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

<!-- ======= Hero Section ======= -->
<section id="hero">

<div class="container">
  <div class="row justify-content-between">
    <div class="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
      <div data-aos="zoom-out">
        <h1><span>JavaScript</span> PLC</h1>
        <h2>Первый по настоящему свободно программируемый контроллер</h2>
        
        <div class="text-center text-lg-start">
          <a href="#about" class="btn-get-started scrollto">Подробнее</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="300">
      <img src="assets/img/hero-img.png" class="img-fluid animated" alt="">
    </div>
  </div>
</div>

<svg class="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
  <defs>
    <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
  </defs>
  <g class="wave1">
    <use xlink:href="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"/>
  </g>
  <g class="wave2">
    <use xlink:href="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"/>
  </g>
  <g class="wave3">
    <use xlink:href="#wave-path" x="50" y="9" fill="#fff"/>
  </g>
</svg>

</section><!-- End Hero -->

<main id="main">

<!-- ======= About Section ======= -->
<section id="about" class="about">
  <div class="container-fluid">

    <div class="row">
      <div class="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
        <a href="/" class="glightbox play-btn mb-4"></a>
      </div>

      <div class="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
        <h3>О проекте</h3>
        <p>PLC.JS — это проект, нацеленный на применения в сферах автоматизации зданий (BMS) и промышленных объектов (АСУ ТП). 

         В основе проекта лежит идея использования современных одноплатных мини-компьютеров вместо морально устаревших IEC PLC.</p>
  
 
  <h4 class="title">Цели проекта</h4>
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
          <div class="icon"><i >

            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" d="M22 22H2V10l7-3v2l5-2v3h3l1-8h3l1 8v12zM12 9.95l-5 2V10l-3 1.32V20h16v-8h-8V9.95zM11 18h2v-4h-2v4zm-4 0h2v-4H7v4zm10-4h-2v4h2v-4z"/></svg>


          </i></div>
          <h4 class="title"><a href="">Смена парадигмы</a></h4>
          <p class="description">
            Создание современной альтернативы классической «МЭК-парадигме» программирования, которая за последние 20 лет сформировала не мало споров и несогласия между инженерами и программистами</p>
        </div>

        <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
          <div class="icon"><i >


            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 13v9H3v-9Zm18 0v2h-2v7h-2v-7h-2v-2Zm-11-2a7.537 7.537 0 0 1 3.96 1.149l1.447-1.45A9.522 9.522 0 0 0 12 9a9.363 9.363 0 0 0-5.333 1.68l1.449 1.453A7.36 7.36 0 0 1 12 11Z"/><path fill="currentColor" d="M12 7a11.494 11.494 0 0 1 6.834 2.27l1.427-1.43A13.48 13.48 0 0 0 12 5a13.333 13.333 0 0 0-8.186 2.822l1.426 1.43A11.343 11.343 0 0 1 12 7Z"/><path fill="currentColor" d="M12 3a15.471 15.471 0 0 1 9.687 3.41l1.427-1.429A17.43 17.43 0 0 0 .96 4.964l1.427 1.429A15.328 15.328 0 0 1 12 3Zm0 10a4.5 4.5 0 1 0 4.5 4.5A4.5 4.5 0 0 0 12 13Zm0 7a2.5 2.5 0 1 1 2.5-2.5A2.5 2.5 0 0 1 12 20Z"/></svg>

          </i></div>
          <h4 class="title"><a href="">Популяризация IoT</a></h4>
          <p class="description">Популяризация современного IoT-оборудования на базе GNU/Linux для задач автоматизации, вместо устаревших IEC PLC</p>
        </div>

        <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
          <div class="icon"><i >


            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 48 48"><path fill="currentColor" d="M11.5 11a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0ZM15 5a6 6 0 1 0 0 12a6 6 0 0 0 0-12Zm14.5 6a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0ZM33 5a6 6 0 1 0 0 12a6 6 0 0 0 0-12ZM4 22.446A3.446 3.446 0 0 1 7.446 19h9.624a7.947 7.947 0 0 0-.93 2.5H7.446a.946.946 0 0 0-.946.946v.429c0 .27.003 1.933 1.019 3.505c.896 1.388 2.723 2.92 6.684 3.102a5.469 5.469 0 0 0-2.464 2.223c-3.222-.632-5.18-2.203-6.32-3.968C4 25.54 4 23.27 4 22.877v-.43Zm29.797 7.036a5.469 5.469 0 0 1 2.464 2.223c3.222-.632 5.18-2.203 6.32-3.968C44 25.54 44 23.27 44 22.877v-.43A3.446 3.446 0 0 0 40.554 19H30.93c.44.763.76 1.605.93 2.5h8.694c.522 0 .946.424.946.946v.429c0 .27-.003 1.933-1.019 3.505c-.896 1.388-2.723 2.92-6.684 3.102ZM24 19.5a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7ZM18 23a6 6 0 1 1 12 0a6 6 0 0 1-12 0Zm-5 11.446A3.446 3.446 0 0 1 16.446 31h15.108A3.446 3.446 0 0 1 35 34.446v.431c0 .394 0 2.663-1.419 4.86C32.098 42.033 29.233 44 24 44s-8.098-1.967-9.581-4.263C13 37.54 13 35.27 13 34.877v-.43Zm3.446-.946a.946.946 0 0 0-.946.946v.429c0 .27.003 1.933 1.019 3.505c.954 1.478 2.964 3.12 7.481 3.12c4.517 0 6.527-1.642 7.481-3.12c1.016-1.572 1.019-3.235 1.019-3.505v-.429a.946.946 0 0 0-.946-.946H16.446Z"/></svg>


  </i></div>
          <h4 class="title"><a href="">Создание сообщества</a></h4>
          <p class="description">Создание Open Source АСУ ТП-фреймво́рка и сообщества единомышленников (документация, примеры, лучшие практики)</p>
        </div>

      </div>
    </div>

  </div>
</section><!-- End About Section -->

<!-- ======= Features Section ======= -->
<section id="features" class="features">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Возможности</h2>
      <p>Что мы предлагаем</p>
    </div>

    <div class="row" data-aos="fade-left">

      <div class="col-lg-3 col-md-4">
              <div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
          <i style="color: #1ACC8D;">
            <svg style="height: 36px; width: 36px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m2.45 10.575l4.2-4.2q.35-.35.825-.5t.975-.05l1.3.275Q8.4 7.7 7.625 9t-1.5 3.15L2.45 10.575Zm5.125 2.275q.575-1.8 1.563-3.4t2.387-3q2.2-2.2 5.025-3.288t5.275-.662q.425 2.45-.65 5.275T17.9 12.8q-1.375 1.375-3 2.388t-3.425 1.587l-3.9-3.925Zm6.9-3q.575.575 1.413.575T17.3 9.85q.575-.575.575-1.412T17.3 7.025q-.575-.575-1.413-.575t-1.412.575Q13.9 7.6 13.9 8.438t.575 1.412Zm-.7 12.025l-1.6-3.675q1.85-.725 3.163-1.5t2.912-2.125l.25 1.3q.1.5-.05.988t-.5.837l-4.175 4.175ZM4.05 16.05q.875-.875 2.125-.888t2.125.863q.875.875.875 2.125T8.3 20.275q-.625.625-2.087 1.075t-4.038.8q.35-2.575.8-4.025T4.05 16.05Z"/></svg>
          </i>
          <h3><a href="#feature-1">Готовое решение</a></h3>
        </div>
 
      </div>
  
      <div class="col-lg-3 col-md-4 mt-4 mt-md-0" id="test1">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="50">
          <i  style="color: #ffbb2c;"> 
             <svg width="36px" height="26px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet"><path d="M0 0h256v256H0V0z" fill="#F7DF1E"/><path d="M67.312 213.932l19.59-11.856c3.78 6.701 7.218 12.371 15.465 12.371 7.905 0 12.89-3.092 12.89-15.12v-81.798h24.057v82.138c0 24.917-14.606 36.259-35.916 36.259-19.245 0-30.416-9.967-36.087-21.996M152.381 211.354l19.588-11.341c5.157 8.421 11.859 14.607 23.715 14.607 9.969 0 16.325-4.984 16.325-11.858 0-8.248-6.53-11.17-17.528-15.98l-6.013-2.58c-17.357-7.387-28.87-16.667-28.87-36.257 0-18.044 13.747-31.792 35.228-31.792 15.294 0 26.292 5.328 34.196 19.247L210.29 147.43c-4.125-7.389-8.591-10.31-15.465-10.31-7.046 0-11.514 4.468-11.514 10.31 0 7.217 4.468 10.14 14.778 14.608l6.014 2.577c20.45 8.765 31.963 17.7 31.963 37.804 0 21.654-17.012 33.51-39.867 33.51-22.339 0-36.774-10.654-43.819-24.574"/></svg> 
    
  
          </i>
        <h3><a href="#feature-2">JavaScript </a></h3>
        </div>
      </div>
  
      <div class="col-lg-3 col-md-4 mt-4 mt-md-0">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
          <i  style="color: #69B7DE;" >
    <svg style="height: 36px; width: 36px" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="currentColor"><path d="M11.031 12.002a.75.75 0 0 0-.75.75v6.56a.75.75 0 0 0 1.5 0v-1.965c0-.11.131-.166.21-.09l2.656 2.493a.75.75 0 1 0 1.027-1.094l-1.786-1.677c-.077-.072-.035-.205.068-.23a2.408 2.408 0 0 0-.562-4.747H11.03Zm.875 3.313a.125.125 0 0 1-.125-.125v-1.563c0-.069.056-.125.125-.125h1.488a.907.907 0 1 1 0 1.813h-1.488Zm-7.788-2.478a.75.75 0 0 1 .75-.75h3.633a.75.75 0 0 1 0 1.5H5.743a.125.125 0 0 0-.125.125v1.577c0 .07.056.125.125.125h2.709a.75.75 0 0 1 0 1.5H5.743a.125.125 0 0 0-.125.125v2.227a.75.75 0 1 1-1.5 0v-6.43Zm13.45-.75a.75.75 0 0 0-.75.75v6.43c0 .414.335.75.75.75H21.2a.75.75 0 1 0 0-1.5h-2.757a.125.125 0 0 1-.125-.126V17.04c0-.069.056-.125.125-.125h2.708a.75.75 0 0 0 0-1.5h-2.708a.125.125 0 0 1-.125-.125v-1.577c0-.07.056-.125.125-.125H21.2a.75.75 0 0 0 0-1.5h-3.632Zm6.179 0a.75.75 0 0 0-.75.75v6.43c0 .414.336.75.75.75h3.633a.75.75 0 1 0 0-1.5h-2.758a.125.125 0 0 1-.125-.126V17.04c0-.069.056-.125.125-.125h2.709a.75.75 0 0 0 0-1.5h-2.709a.125.125 0 0 1-.125-.125v-1.577c0-.07.056-.125.125-.125h2.758a.75.75 0 0 0 0-1.5h-3.633Z"/><path d="M6 1a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V6a5 5 0 0 0-5-5H6ZM3 6a3 3 0 0 1 3-3h20a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Z"/></g></svg></i>
   <h3><a href="#feature-3">Free Software</a></h3>
        </div>
      </div>
  
      <div class="col-lg-3 col-md-4 mt-4 mt-lg-0">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="150">
          <i   style="color: #e80368;">
    <svg style="height: 36px; width: 36px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M21.007 8.222A3.738 3.738 0 0 0 15.045 5.2a3.737 3.737 0 0 0 1.156 6.583a2.988 2.988 0 0 1-2.668 1.67h-2.99a4.456 4.456 0 0 0-2.989 1.165V7.4a3.737 3.737 0 1 0-1.494 0v9.117a3.776 3.776 0 1 0 1.816.099a2.99 2.99 0 0 1 2.668-1.667h2.99a4.484 4.484 0 0 0 4.223-3.039a3.736 3.736 0 0 0 3.25-3.687zM4.565 3.738a2.242 2.242 0 1 1 4.484 0a2.242 2.242 0 0 1-4.484 0zm4.484 16.441a2.242 2.242 0 1 1-4.484 0a2.242 2.242 0 0 1 4.484 0zm8.221-9.715a2.242 2.242 0 1 1 0-4.485a2.242 2.242 0 0 1 0 4.485z"/></svg>
    </i>
          <h3><a href="#feature-4">Open Source</a></h3>
        </div>
      </div>

     <!--
 <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="250">
          <i class="ri-database-2-line" style="color: #47aeff;"></i>
          <h3><a href="">Поддержка</a></h3>
        </div>
      </div>
       <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
          <i class="ri-gradienter-line" style="color: #ffa76e;"></i>
          <h3><a href="">Eiusmod Tempor</a></h3>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="350">
          <i class="ri-file-list-3-line" style="color: #11dbcf;"></i>
          <h3><a href="">Midela Teren</a></h3>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="400">
          <i class="ri-price-tag-2-line" style="color: #4233ff;"></i>
          <h3><a href="">Pira Neve</a></h3>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="450">
          <i class="ri-anchor-line" style="color: #b2904f;"></i>
          <h3><a href="">Dirada Pack</a></h3>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="500">
          <i class="ri-disc-line" style="color: #b20969;"></i>
          <h3><a href="">Moton Ideal</a></h3>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="550">
          <i class="ri-base-station-line" style="color: #ff5828;"></i>
          <h3><a href="">Verdo Park</a></h3>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 mt-4">
        <div class="icon-box" data-aos="zoom-in" data-aos-delay="600">
          <i class="ri-fingerprint-line" style="color: #29cc61;"></i>
          <h3><a href="">Flavor Nivelanda</a></h3>
        </div>
      </div>
    </div> -->
  </div>
  </div>
</section><!-- End Features Section -->

<!-- ======= Counts Section ======= 
<section id="counts" class="counts">
  <div class="container">

    <div class="row" data-aos="fade-up">

      <div class="col-lg-3 col-md-6">
        <div class="count-box">
          <i class="bi bi-emoji-smile"></i>
          <span data-purecounter-start="0" data-purecounter-end="2" data-purecounter-duration="1" class="purecounter"></span>
          <p>Довольных клиентов</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
        <div class="count-box">
          <i class="bi bi-journal-richtext"></i>
          <span data-purecounter-start="0" data-purecounter-end="2" data-purecounter-duration="1" class="purecounter"></span>
          <p>Реализованный проектов</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
        <div class="count-box">
          <i class="bi bi-headset"></i>
          <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter"></span>
          <p>Hours Of Support</p>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
        <div class="count-box">
          <i class="bi bi-people"></i>
          <span data-purecounter-start="0" data-purecounter-end="3" data-purecounter-duration="1" class="purecounter"></span>
          <p>Жесткий работников</p>
        </div>
      </div>

    </div>

  </div>
</section>
-->
<!-- End Counts Section -->

<!-- ======= Details Section ======= -->
<section id="details" class="details">
  <div class="container">

    <div class="row content" id="feature-1">
      <div class="col-md-4" data-aos="fade-right">
       
        <i style="color: #1ACC8D;">
          <svg style="height: 240px; width: 240px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m2.45 10.575l4.2-4.2q.35-.35.825-.5t.975-.05l1.3.275Q8.4 7.7 7.625 9t-1.5 3.15L2.45 10.575Zm5.125 2.275q.575-1.8 1.563-3.4t2.387-3q2.2-2.2 5.025-3.288t5.275-.662q.425 2.45-.65 5.275T17.9 12.8q-1.375 1.375-3 2.388t-3.425 1.587l-3.9-3.925Zm6.9-3q.575.575 1.413.575T17.3 9.85q.575-.575.575-1.412T17.3 7.025q-.575-.575-1.413-.575t-1.412.575Q13.9 7.6 13.9 8.438t.575 1.412Zm-.7 12.025l-1.6-3.675q1.85-.725 3.163-1.5t2.912-2.125l.25 1.3q.1.5-.05.988t-.5.837l-4.175 4.175ZM4.05 16.05q.875-.875 2.125-.888t2.125.863q.875.875.875 2.125T8.3 20.275q-.625.625-2.087 1.075t-4.038.8q.35-2.575.8-4.025T4.05 16.05Z"/></svg>
          </i>

      </div>
      <div class="col-md-8 pt-4" data-aos="fade-up">
        <h3 >Готовое решение </h3>
        <p>
        Предлагаем IIoT решения готовые к реальным задачам в области автоматизации.
        </p>
          Для этого мы специально спроектировали и наладили серийное производство дешевых устройств на базе современных одноплатников по стандартам промышленной автоматизации.
          <p>
        <ul>
          <li><i class="bi bi-check"></i> Крепление на DIN-рейку</li>
          <li><i class="bi bi-check"></i> Питание 24 VDC</li>
          <li><i class="bi bi-check"></i> Защита от влаги, наводок, вибрации</li>
        </ul>
      </p>
        <p>
          Приобрести устройства PLC.JS вы можете в нашем интернет-магизине <a style="font-weight: bold;" href="http://shop.fatec.ru">shop.fatec.ru</a>
          </p>
      </div>
    </div>

    <div class="row content" id="feature-2">
      <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
        <i style="color: #ffbb2c;"> 
           <svg width="240px" height="240px" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet"><path d="M0 0h256v256H0V0z" fill="#F7DF1E"/><path d="M67.312 213.932l19.59-11.856c3.78 6.701 7.218 12.371 15.465 12.371 7.905 0 12.89-3.092 12.89-15.12v-81.798h24.057v82.138c0 24.917-14.606 36.259-35.916 36.259-19.245 0-30.416-9.967-36.087-21.996M152.381 211.354l19.588-11.341c5.157 8.421 11.859 14.607 23.715 14.607 9.969 0 16.325-4.984 16.325-11.858 0-8.248-6.53-11.17-17.528-15.98l-6.013-2.58c-17.357-7.387-28.87-16.667-28.87-36.257 0-18.044 13.747-31.792 35.228-31.792 15.294 0 26.292 5.328 34.196 19.247L210.29 147.43c-4.125-7.389-8.591-10.31-15.465-10.31-7.046 0-11.514 4.468-11.514 10.31 0 7.217 4.468 10.14 14.778 14.608l6.014 2.577c20.45 8.765 31.963 17.7 31.963 37.804 0 21.654-17.012 33.51-39.867 33.51-22.339 0-36.774-10.654-43.819-24.574"/></svg> 
        </i>
      </div>
      <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
        <h3>JavaScript</h3>
        <p>
          JavaScript и Node.js в качестве основного языка и платформы выбраны не случайно:
        </p>

        <ul>
          <li><i class="bi bi-check"></i> Самый популярный язык программирования - самое большое сообщество</li>
          <li><i class="bi bi-check"></i> Высокая производительность и эффективная обработка событий</li>
          <li><i class="bi bi-check"></i> Широкий выбор библиотек и модулей </li>
        </ul>
        <p>
          Забудьте об устаревших IDE и ладерной логике! Пишите код в ваших любимых IDE! 
          Привлекайте профессиональных ИТ-специалистов! Используйте современные методологии для разработки ваших проектов!
        </p>
      </div>
    </div>

    <div class="row content" id="feature-3">
      <div class="col-md-4" data-aos="fade-right">
        <i  style="color: #69B7DE;" >
          <svg style="height: 240px; width: 240px" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="currentColor"><path d="M11.031 12.002a.75.75 0 0 0-.75.75v6.56a.75.75 0 0 0 1.5 0v-1.965c0-.11.131-.166.21-.09l2.656 2.493a.75.75 0 1 0 1.027-1.094l-1.786-1.677c-.077-.072-.035-.205.068-.23a2.408 2.408 0 0 0-.562-4.747H11.03Zm.875 3.313a.125.125 0 0 1-.125-.125v-1.563c0-.069.056-.125.125-.125h1.488a.907.907 0 1 1 0 1.813h-1.488Zm-7.788-2.478a.75.75 0 0 1 .75-.75h3.633a.75.75 0 0 1 0 1.5H5.743a.125.125 0 0 0-.125.125v1.577c0 .07.056.125.125.125h2.709a.75.75 0 0 1 0 1.5H5.743a.125.125 0 0 0-.125.125v2.227a.75.75 0 1 1-1.5 0v-6.43Zm13.45-.75a.75.75 0 0 0-.75.75v6.43c0 .414.335.75.75.75H21.2a.75.75 0 1 0 0-1.5h-2.757a.125.125 0 0 1-.125-.126V17.04c0-.069.056-.125.125-.125h2.708a.75.75 0 0 0 0-1.5h-2.708a.125.125 0 0 1-.125-.125v-1.577c0-.07.056-.125.125-.125H21.2a.75.75 0 0 0 0-1.5h-3.632Zm6.179 0a.75.75 0 0 0-.75.75v6.43c0 .414.336.75.75.75h3.633a.75.75 0 1 0 0-1.5h-2.758a.125.125 0 0 1-.125-.126V17.04c0-.069.056-.125.125-.125h2.709a.75.75 0 0 0 0-1.5h-2.709a.125.125 0 0 1-.125-.125v-1.577c0-.07.056-.125.125-.125h2.758a.75.75 0 0 0 0-1.5h-3.633Z"/><path d="M6 1a5 5 0 0 0-5 5v20a5 5 0 0 0 5 5h20a5 5 0 0 0 5-5V6a5 5 0 0 0-5-5H6ZM3 6a3 3 0 0 1 3-3h20a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Z"/></g></svg>
        </i>
                
      </div>
      <div class="col-md-8 pt-5" data-aos="fade-up">
        <h3>Free Software</h3>
        <p>Предлагаем бесплатное ПО для быстрой разработки инженерных проектов.</p>
        <ul>
          <li><i class="bi bi-check"></i> Никаких лицензий</li>
          <li><i class="bi bi-check"></i> Удобная симуляция и отладка инженерного проекта до загрузки в контроллер</li>
          <li><i class="bi bi-check"></i> Поддержка самых популярных промышленных модулей ввода-вывода</li>
        </ul>
        <p>
          Вы удивитесь на сколько быстро и просто разрабатывать инженерное ПО. <strong>Релиз ПО запланирован на конец 2024 года.</strong>
        </p>
         
      </div>
    </div>

    <div class="row content" id="feature-4">
      <div class="col-md-4 order-1 order-md-2" data-aos="fade-left">
        <i   style="color: #e80368;">
          <svg style="height: 240px; width: 240px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M21.007 8.222A3.738 3.738 0 0 0 15.045 5.2a3.737 3.737 0 0 0 1.156 6.583a2.988 2.988 0 0 1-2.668 1.67h-2.99a4.456 4.456 0 0 0-2.989 1.165V7.4a3.737 3.737 0 1 0-1.494 0v9.117a3.776 3.776 0 1 0 1.816.099a2.99 2.99 0 0 1 2.668-1.667h2.99a4.484 4.484 0 0 0 4.223-3.039a3.736 3.736 0 0 0 3.25-3.687zM4.565 3.738a2.242 2.242 0 1 1 4.484 0a2.242 2.242 0 0 1-4.484 0zm4.484 16.441a2.242 2.242 0 1 1-4.484 0a2.242 2.242 0 0 1 4.484 0zm8.221-9.715a2.242 2.242 0 1 1 0-4.485a2.242 2.242 0 0 1 0 4.485z"/></svg>
          </i>
      </div>
      <div class="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
        <h3>Open Source</h3>
        <p>
          Программное обеспечение для разработки инженерных проектов, которое мы бесплатно поставляем для наших устройств, не только бесплатное, но и имеет открытый код. Исходный код приложения доступен для просмотра, использования и изменения любым желающим.
        </p>
    
        <ul>
          <li><i class="bi bi-check"></i> ПО можно доработать (изменить, дополнить) под свои задачи</li>
          <li><i class="bi bi-check"></i> Использовать для разработки других программ и т. д.</li>
          <li><i class="bi bi-check"></i> Изучить на наличие уязвимостей</li>
        </ul>
  
  <p >
        Мы будем рады, если наши наработки мотивируют производителей IIoT разрабатывать более универсальные и доступные устройства!
        </p>
      </div>
  
    </div>

  </div>
</section><!-- End Details Section -->

<!-- ======= Gallery Section ======= -->
<section id="gallery" class="gallery">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Галерея</h2>
      <p>Фотографии</p>
    </div>

    <div class="row g-0" data-aos="fade-left">

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="100" >  
          <a href="assets/img/gallery/gallery-1.jpg" class="gallery-lightbox" title="">
            <img src="assets/img/gallery/gallery-1.jpg" alt="" class="img-fluid" >
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="150">
          <a href="assets/img/gallery/gallery-2.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-2.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="200">
          <a href="assets/img/gallery/gallery-3.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-3.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="250">
          <a href="assets/img/gallery/gallery-4.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-4.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="300">
          <a href="assets/img/gallery/gallery-5.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-5.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="350">
          <a href="assets/img/gallery/gallery-6.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-6.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="400">
          <a href="assets/img/gallery/gallery-7.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-7.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-4">
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="450">
          <a href="assets/img/gallery/gallery-8.jpg" class="gallery-lightbox">
            <img src="assets/img/gallery/gallery-8.jpg" alt="" class="img-fluid">
          </a>
        </div>
      </div>

    </div>

  </div>
</section><!-- End Gallery Section -->

<!-- ======= Testimonials Section ======= -->
<!-- <section id="testimonials" class="testimonials">
  <div class="container">

    <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="500">
      <div class="swiper-wrapper">

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
            <h3>Чистюхин Алексей</h3>
            <h4>Founder LLC NAS</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Парни молодцы, работают за идею, особенно Сергей Дунаевский, настоящий Hard Worker!
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div> 

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
            <h3>Чеснов Евгений</h3>
            <h4>Дизайнер</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Наконец то кто то сделал Python PLC!
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div>
 

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
            <h3>Дунаевский Антон</h3>
            <h4>Шеф-монтажник</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Вчера запустили чиллер на Plc.Js - это просто песня ребята!
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div> 

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
            <h3>Голубь Андрей</h3>
            <h4>Ceo LLC NAS</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Делаем тиражируемое решение для вентиляции на базе Plc.Js. Разработку ПО заказали у фрилансеров с 10 летним опытом разработки на Python. Спасибо ребятам что сделали такую железку!
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div> 

        <div class="swiper-slide">
          <div class="testimonial-item">
            <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
            <h3>Дементьев Антон</h3>
            <h4>Программист</h4>
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              Пишу код в VS Code и плачу от счастья! До этого писал в Codesys.. Теперь только Plc.Js!
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
          </div>
        </div> 

      </div>
      <div class="swiper-pagination"></div>
    </div>

  </div>
</section> -->
<!-- End Testimonials Section -->

<!-- ======= Team Section ======= -->
<!-- <section id="team" class="team">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Команда</h2>
      <p>Наша бригада</p>
    </div>
     
    <div class="row" data-aos="fade-left">
      
      <div class="col-lg-3 col-md-6">
        <div class="member" data-aos="zoom-in" data-aos-delay="100">
          <div class="pic"><img src="assets/img/team/team-1.jpg" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4></h4>
            <span>Главный разработчик</span>
            <div class="social">
              <a href=""><i class="bi bi-twitter"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-5 mt-md-0">
        <div class="member" data-aos="zoom-in" data-aos-delay="200">
          <div class="pic"><img src="assets/img/team/team-2.jpg" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4></h4>
            <span>Главный проектировщик</span>
            <div class="social">
              <a href=""><i class="bi bi-twitter"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
        <div class="member" data-aos="zoom-in" data-aos-delay="300">
          <div class="pic"><img src="assets/img/team/team-3.jpg" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4></h4>
            <span>Главный программист</span>
            <div class="social">
              <a href=""><i class="bi bi-twitter"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
        <div class="member" data-aos="zoom-in" data-aos-delay="400">
          <div class="pic"><img src="assets/img/team/team-4.jpg" class="img-fluid" alt=""></div>
          <div class="member-info">
            <h4></h4>
            <span>Главный инженер</span>
            <div class="social">
              <a href=""><i class="bi bi-twitter"></i></a>
              <a href=""><i class="bi bi-facebook"></i></a>
              <a href=""><i class="bi bi-instagram"></i></a>
              <a href=""><i class="bi bi-linkedin"></i></a>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</section> -->
<!-- End Team Section -->

<!-- ======= Pricing Section ======= -->
<!-- <section id="pricing" class="pricing">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Цены</h2>
      <p>Варианты приобретения</p>
    </div>

 
    

    <div class="row" data-aos="fade-left" >
        

              <div class="col-lg-12 col-md-6" v-show="stepN === 100">
                <div class="box" data-aos="zoom-in" data-aos-delay="100">
                  <h3>1. PyLC</h3>
                  Вы хотите купить только контроллер? 
                 
                  <div style="margin: 10px;"><mark>Данный вариант предполагает, что IO-модули расширения, сборку электрощита, программирование вы делаете самостоятельно.</mark></div>
                  <div class="btn-wrap">
                    <a style="margin: 5px;" class="btn-buy" @click.prevent="stepBack">Назад</a>
                    <a style="margin: 5px;" class="btn-buy" @click.prevent="stepBack">Далее</a>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6" v-show="stepN === 200">
                <div class="box" data-aos="zoom-in" data-aos-delay="100">
                  <h3>2. PYLC + IO-модули</h3>
                  Вы хотите купить контроллер и необходимый набор IO-модулей расширения? 
                  
                  <div style="margin: 10px;"><mark>Данный вариант предполагает, что сборку электрощита и программирование вы делаете самостоятельно.</mark></div>
                  <div class="btn-wrap">
                    <a style="margin: 5px;" class="btn-buy" @click.prevent="stepBack">Назад</a>
                    <a style="margin: 5px;" class="btn-buy" @click.prevent="stepBack">Далее</a>
                  </div>
                </div>
              </div>

              
              <div class="col-lg-12 col-md-6" v-show="stepN === 300">
                <div class="box" data-aos="zoom-in" data-aos-delay="100">
                  <h3>3. Реализация под ключ</h3>
                  Вы хотите купить контроллер и необходимый набор IO-модулей расширения? 
                  <div style="margin: 10px;"><mark>Данный вариант предполагает, что вместе с оборудованием вы преобретаете у нас услги по разработке проекта.</mark></div>
                  <div class="btn-wrap">
                    <a style="margin: 5px;" class="btn-buy" @click.prevent="stepBack">Назад</a>
                    <a style="margin: 5px;" class="btn-buy" @click.prevent="stepBack">Далее</a>
                  </div>
                </div>
              </div>


      <div class="col-lg-6 col-md-6" v-show="stepN === 0">
        <div class="box" data-aos="zoom-in" data-aos-delay="100">
          <h3>ОБОРУДОВАНИЕ</h3>
          <h4><sup></sup><span>от</span> 6000<span> руб</span></h4>
          
          <div  >Вы можете приобрести PYLC и все необходимые IO-модули в нашем интернет магазине</div>
        
          <div class="btn-wrap">
            <a href="#" class="btn-buy" @click.prevent="toStep(100)">Купить</a>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-lg-6 col-md-6 mt-4 mt-md-0" v-show="stepN === 0">
        <div class="box" data-aos="zoom-in" data-aos-delay="200">
          <h3>2. PYLC + IO-модули</h3>
          <h4><sup></sup>30-60<span> т.р.</span></h4>
          <ul>
            <li>Контроллер</li>
            <li>+IO-модули</li>
            <li class="na">+Реализация проекта</li>
          </ul>
          <div class="btn-wrap">
            <a href="#" class="btn-buy" @click.prevent="toStep(200)">Купить</a>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-lg-3 col-md-6 mt-4 mt-lg-0" v-show="stepN === 0">
        <div class="box" data-aos="zoom-in" data-aos-delay="300">
          <h3>Щит управления</h3>
          <h4><sup></sup>60-800<span> т.р.</span></h4>
          <ul>
            <li>Контроллер</li>
            <li >+IO-модули</li>
            <li>+Щит автоматики</li>
    <li class="na">+Реализация проекта</li>
          </ul>
          <div class="btn-wrap">
            <a href="#" class="btn-buy">Купить</a>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-lg-6 col-md-6 mt-4 mt-lg-0" v-show="stepN === 0">
        <div class="box" data-aos="zoom-in" data-aos-delay="400">
          <span class="advanced">Эксклюзив</span>
          <h3>ПРОЕКТ ПОД КЛЮЧ</h3>
          <h4><sup></sup><span>от</span> 200 000<span> руб</span></h4>
          
          <div >Вы можете заказать у нас реализацию Вашего проекта: проектирование, сборка, программирование, пуско-наладка
  
          </div>
          <div class="btn-wrap">
            <a href="#" class="btn-buy" @click.prevent="toStep(300)">Купить</a>
          </div>
        </div>
      </div>

    </div>

  </div>
</section> -->

<!-- End Pricing Section -->

<!-- ======= F.A.Q Section ======= -->
<section id="faq" class="faq section-bg">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>F.A.Q</h2>
      <p>Часто задаваемые вопросы</p>
    </div>

    <div class="faq-list">
      <ul>

        <li data-aos="fade-up">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq-list-0">А что не так с МЭК-стандартом?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-0" class="collapse" data-bs-parent=".faq-list-0">

            <p>
              На наш взгляд стандарт IEC 61131-3 вместе с известными плюсам несет с собой серьезные ограничения, которые не позволяют сфере АСУ ТП развиваться.
              Рекомендуем к прочтению статью на эту тему: https://habr.com/ru/articles/736320 
            </p>

            <p>
            Цель данного проекта не борьба с IEC 61131, а создание современной альтернативы, которая позволит решать задачи автоматизации используя современные подходы. 
            </p>

          </div>
        </li>

        <li data-aos="fade-up">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#faq-list-1">Что внутри PLC.JS? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-1" class="collapse" data-bs-parent=".faq-list-1">
            <p>
              Текущая версия PLC.JS построена на базе одноплатников Orange PI R1.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="100">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">Вы хотите сказать что PLC.JS можно применять вместо классических PLC? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list-2">
            <p>
              Именно! Как показывает практика и ряд наших испытаний — самые обычные, стоковые IoT устройства в нормальном исполнении (защитный корпус, хорошее питание и крепление) абсолютно ничем не уступают по надежности классическим PLC среднего уровня (таким как Carel, Danfoss, EVCO, SE)
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">А как же детерминация цикла, МЭК, экстремальное программирование, Safety? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list-3">
            <p>
              Мы не предлагаем и не рекомендуем использовать IoT вместо проверенных временем DCS на нефтебазы, системы ПАЗ или станки с изохронным техпроцессом. 
              Но для типовых задач малой и средней автоматизации IoT устройства на базе GNU/Linux отлично работают!
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">Не хочу ничего покупать, можно просто использовать ваши наработки?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list-4">
            <p> 
              Все исходники и конструкторские наработки будут публиковаться в открытом доступе по мере завития проекта (см. раздел Поддержка).
              Мы будем только рады если вы вдохновитесь нашими наработками и разработаете собственное решение!
            </p>
          </div>
        </li>



        <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">Мы производим модули ввода-вывода, вы можете добавить поддержку нашей периферии в PLC.JS? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list-5">
            <p>
              Направьте на адрес <b>contact@fatec.ru</b> информацию о вашем оборудовании и мы постараемся добавить его поддержку в первую очередь.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-6" class="collapsed">Вы продаете устройства по себестоимости, за счет чего существует проект? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-6" class="collapse" data-bs-parent=".faq-list-6">
            <p>
              Разумеется мы не можем производить устройства себе в минус и минимальная наценка за сборку заложена в стоимость. 
              Так же мы планируем запуск интернет-магазина, где можно будет преобрести не только устройства PLC.JS но и совместимые с ними модули переферии.
            </p>
          </div>
        </li>

        <li data-aos="fade-up" data-aos-delay="200">
          <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-7" class="collapsed">Какую Open Source лицензию  вы используете? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
          <div id="faq-list-7" class="collapse" data-bs-parent=".faq-list-7">
            <p>
              Apache License 2.0 — от пользователей она требует только, если работа была скопирована и изменена, писать об этом, и, конечно, указывать исходное авторство. Лицензия отдельно оговаривает, что для производных работ нельзя использовать те же названия, если они являются торговым марками.
            </p>
          </div>
        </li>

      </ul>
    </div>

  </div>
</section><!-- End F.A.Q Section -->

<!-- ======= Contact Section ======= -->
<section id="contact" class="contact">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Контакты</h2>
      <p>Наши контакты</p>
    </div>

    <div class="row">

      <div class="col-lg-4" data-aos="fade-right" data-aos-delay="100">
        <div class="info">
          <div class="address">
            <i class="bi bi-geo-alt"></i>
            <h4>Адрес:</h4>
            <p>Москва, Новгородская, 1 (БЦ Депо)</p>
          </div>

          <div class="email">
            <i class="bi bi-envelope"></i>
            <h4>Email:</h4>
            <p>contact@fatec.ru</p>
          </div>

          <div class="phone">
            <i class="bi bi-phone"></i>
            <h4>Телефон:</h4>
            <p>8 (965) 366-48-00</p>
          </div>

          <div class="phone">
            <i class="bi bi-telegram"></i>
            <h4>Telegram:</h4>
            <p><a href="http://t.me/plcjs">t.me/plcjs</a></p>
          </div>

        </div>

      </div>

      
        <div class="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left" data-aos-delay="200">
          
         
     
          <!-- <p style="color: #010483; font-size: small;">Желающим помочь проекту материально: 
          
          <br><strong>BTC:</strong> 13FEp6ucJhsV1Xw7U3tJm7FenkbfV4Sumw
          <br><strong>USDT (TRC20):</strong> TJ7Pb1JcRRZU2QNogLhHZ1TdcUJD3t6gQ4 
          <br><strong>Сбербанк:</strong> 4276380099837846 
          </p> -->


        <!-- <form action="forms/contact.php" method="post" role="form" class="php-email-form">
          <div class="row">
            <div class="col-md-6 form-group">
              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0">
              <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
          </div>
          <div class="form-group mt-3">
            <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
          </div>
          <div class="my-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div>
          <div class="text-center"><button type="submit">Отправить</button></div>
        </form> -->

      </div>  

    </div>

  </div>
</section><!-- End Contact Section -->

</main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer">

<div class="container">
  <div class="copyright">
    &copy; Copyright <strong><span>PLC.JS</span></strong>. All Rights Reserved
  </div>
  <div class="credits">
    <!-- All the links in the footer should remain intact. -->
    <!-- You can delete the links only if you purchased the pro version. -->
    <!-- Licensing information: https://bootstrapmade.com/license/ -->
    <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/bootslander-free-bootstrap-landing-page-template/ -->
    <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
  </div>
</div>
</footer><!-- End Footer -->

<!-- <component src="assets/vendor/purecounter/purecounter_vanilla.js" :is="'script'"></component>
<component src="assets/vendor/aos/aos.js" :is="'script'"></component>
<component src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js" :is="'script'"></component>
<component src="assets/vendor/glightbox/js/glightbox.min.js" :is="'script'"></component>
<component src="assets/vendor/swiper/swiper-bundle.min.js" :is="'script' "></component> -->
 
<!-- <component src="assets/js/main.js" :is="'script'"></component> -->
<!-- <component src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js" :is="'script'"></component> -->
    
</template>

<script  setup>
  import { useAuthStore } from '@/authStore';

  const useAuthStore1 = useAuthStore()


// export default {
//   name: 'HelloWorld',
//   props: {
//     msg: String
//   },
  
//   data() {
//     return {
//       stepN: 0
//     }
//   },

//   methods:{
//     nextStep() {
//       this.stepN++
//     },

//     toStep(val) {
//       this.stepN=val
//     },

//     stepBack() {
//       this.stepN=0
//     }
//   }
 
// }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
