<template>

 <!-- ======= Header ======= -->
 <header id="header" class="fixed-top d-flex align-items-center ">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
        <a href="/" style="display: inline-block;"> 
        <object style="pointer-events: none; height: 40px" type="image/svg+xml" data="assets/img/plcjs-logo.svg"></object>
        </a>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto" href="/#hero">Главная</a></li>
          <li><a class="nav-link scrollto" href="/#about" >О проекте</a></li>
          <li><a class="nav-link scrollto" href="/#features">Возможности</a></li>
          <li><a class="nav-link scrollto" href="/#gallery">Галерея</a></li>
          <li><a class="nav-link scrollto"  href="/store">Купить</a></li>
          <li><a class="nav-link scrollto" href="/#faq">FAQ</a></li>
          <li class="dropdown"><a href="#"><span>Поддержка</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              
              <li><a href="https://gitflic.ru/project/glazko/plcjs.wiki" target="_blank">Документация</a></li>
              <li><a href="https://gitflic.ru/project/glazko/plcjs.wiki">Программное обеспечение</a></li>
              <li><a href="/sert">Сертификаты</a></li>
              <li><a href="http://t.me/plcjs" target="_blank">t.me/plcjs</a></li>
            </ul>
          </li>
          <li><a class="nav-link scrollto" href="/#contact">Контакты</a></li>
        
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->

  <main id="main">

    <!-- ======= Breadcrumbs Section ======= -->
    <section class="breadcrumbs">
      <div class="container">


        <div   class="d-flex justify-content-between align-items-center">
          <h1>Сертификаты</h1>
          <ol>
            <li><a href="/">Главная</a></li>
            <li>Поддержка</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs Section -->
    

    <section class="inner-page">
      <div class="container" >
        <p>
          Устройства PLC.JS собираются в России на собственном сборочном участке (Москва, Новгородская, 1) по нашей конструкторской документации
        </p>
      </div>

 
    </section>

 <!-- ======= Details Section ======= -->
<section id="details" class="details">
  <div class="container">

    <div class="row content" id="feature-1">
      <div class="col-md-4" data-aos="fade-right">
       
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="100" >  
          <a href="assets/img/Sert1.jpg" class="gallery-lightbox" title="Декларация, подтверждающая соответствие техническому регламенту Таможенного союза О безопасности низковольтного оборудования (ТР ТС 004/2011) ">
            <img src="assets/img/sert1-preview.jpg"   alt="" class="img-fluid" >
          </a>
        </div> 

      </div>
      <div class="col-md-8 pt-4" data-aos="fade-up">
        <h3>ТР ТС 020/2011</h3>
        <p>
        Декларация, подтверждающая соответствие техническому регламенту Таможенного союза "Электромагнитная совместимость технических средств" (ТР ТС 020/2011)  
        </p>
      </div>
    </div>

    <div class="row content" id="feature-2">
      <div class="col-md-4" data-aos="fade-right">
       
        <div class="gallery-item" data-aos="zoom-in" data-aos-delay="100" >  
          <a href="assets/img/Sert2.jpg" class="gallery-lightbox" title="Декларация, подтверждающая соответствие техническому регламенту Таможенного союза О безопасности низковольтного оборудования (ТР ТС 004/2011) ">
            <img src="assets/img/sert2-preview.jpg"   alt="" class="img-fluid" >
          </a>
        </div> 

      </div>
      <div class="col-md-8 pt-4" data-aos="fade-up">
        <h3>ТР ТС 004/2011</h3>
        <p>
          Декларация, подтверждающая соответствие техническому регламенту Таможенного союза "О безопасности низковольтного оборудования" (ТР ТС 004/2011) 
        </p>
      </div>
    </div>

  </div>
</section><!-- End Details Section -->



  </main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer">

<div class="container">
  <div class="copyright">
    &copy;<strong><span>plc.js</span></strong>. Все права защищены
  </div>
  <div class="credits">
    <!-- All the links in the footer should remain intact. -->
    <!-- You can delete the links only if you purchased the pro version. -->
    <!-- Licensing information: https://bootstrapmade.com/license/ -->
    <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/bootslander-free-bootstrap-landing-page-template/ -->
    <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
  </div>
</div>
</footer><!-- End Footer -->

</template>


<script setup>
  import { useAuthStore } from '@/authStore';
 
  const useAuthStore1 = useAuthStore()
 
</script>